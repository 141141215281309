import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  TemplateRef,
  input,
  model,
  output,
  viewChild,
} from '@angular/core';
import { ClickOutSideDirective } from 'areeb-core-web-ag';

@Component({
  selector: 'lib-tooltip',
  standalone: true,
  imports: [CommonModule, ClickOutSideDirective],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
})
export class TooltipComponent implements AfterViewInit {
  isOpened = model<boolean>(false);

  toolTipTemplate = input<TemplateRef<unknown> | null>(null);

  hasCloseIcon = input<boolean>(true);
  hasActionButton = input<boolean>(false);
  position = input<'absolute' | 'fixed'>('fixed');
  title = input.required<string>();
  description = input<string>();
  toolTipClass = input<string>('');
  toolTipAction = input<'click' | 'mouseenter'>('mouseenter');

  top = model<string>('0px');
  left = model<string>('0px');

  actionButtonLabel = input<string>();
  action = output();
  close = output();

  placement = input<TooltipPlacement>('right');

  onMouseLeaveToolTip = output();

  tooltipTemplate = viewChild<ElementRef>('tooltip');
  templateHeight!: number;
  templateWidth!: number;

  ngAfterViewInit() {
    this.templateHeight = this.tooltipTemplate()?.nativeElement.offsetHeight;
    this.templateWidth = this.tooltipTemplate()?.nativeElement.offsetWidth;
  }

  makeActionWhenClickActionButton() {
    this.action.emit();
  }

  onMouseLeave() {
    this.onMouseLeaveToolTip.emit();
  }

  closeTooltip() {
    this.close.emit();
  }

  handleClickOutsideTooltip() {
    if (this.isOpened()) {
      this.close.emit();
    }
  }
}

export type TooltipPlacement = 'top' | 'left' | 'bottom' | 'right';
